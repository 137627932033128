import { TypographyProps } from '@mui/material';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { HOME_PATH } from '@/constants/routes';

interface NavigationOptions {
  left: 'logo' | 'back' | null;
  backUrl: string;
  title: string;
  titleProps: Omit<TypographyProps, 'ref' | 'component'> | null;
  right: string;
  backgroundColor: string;
  showRightMenu: boolean;
  showDismissButton: boolean;
  showHeader: boolean;
  showFooter: boolean;
}

interface NavigationContextValue {
  options: NavigationOptions;
  setOptions: (input: Partial<NavigationOptions>) => void;
}

const INIT_OPTIONS: NavigationOptions = {
  left: 'logo',
  backUrl: HOME_PATH,
  title: '',
  titleProps: null,
  right: '',
  backgroundColor: 'transparent',
  showRightMenu: false,
  showDismissButton: false,
  showHeader: true,
  showFooter: false,
};

export const NavigationContext = createContext<NavigationContextValue>({
  options: INIT_OPTIONS,
  setOptions: () => null,
});

interface NavigationProviderProps {
  children: React.ReactNode;
}

export const NavigationProvider = React.memo<NavigationProviderProps>(props => {
  const [NavigationOptions, setNavigationOptions] = useState<NavigationOptions>(INIT_OPTIONS);

  const setOptions = useCallback(
    (input: Partial<NavigationOptions>) =>
      setNavigationOptions({
        ...INIT_OPTIONS,
        ...input,
      }),
    [],
  );

  const contextValue = useMemo(() => {
    return {
      options: NavigationOptions,
      setOptions,
    };
  }, [NavigationOptions, setOptions]);

  return <NavigationContext.Provider value={contextValue}>{props.children}</NavigationContext.Provider>;
});

export const useNavigation = (): NavigationContextValue => {
  const { options, setOptions } = useContext(NavigationContext);

  const { t } = useTranslation();
  useEffect(() => {
    document.title = options.title || t('common.qubic_pass');
  }, [options.title, t]);

  return {
    options,
    setOptions,
  };
};
