import { ExternalProvider } from '@ethersproject/providers';

/**
 * Ref: https://eips.ethereum.org/EIPS/eip-1193#rpc-errors
 */
interface ProviderRpcError extends Error {
  code: number;
  data?: unknown;
}

export const switchWalletChain = async (provider: ExternalProvider, targetChainId: number): Promise<boolean> => {
  if (!provider || !provider.request) {
    return false;
  }
  try {
    const targetHexChainId = `0x${targetChainId.toString(16)}`;
    const hexChainId = await provider.request({ method: 'eth_chainId' });
    const walletChainId = parseInt(hexChainId, 16);
    if (walletChainId !== targetChainId) {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: targetHexChainId }],
      });
    }
    return true;
  } catch (error) {
    // This error code indicates that the chain has not been added to MetaMask
    if ((error as ProviderRpcError).code === 4902) {
      alert(
        `Your wallet not support network ID: ${targetChainId}\nFind more information: https://chainlist.org/?search=${targetChainId}`,
      );
    }
    console.error('Switch wallet chain error', error);
    return false;
  }
};
