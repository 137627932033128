import { AppBar, Box, Container, IconButton, Menu, MenuItem, Stack, styled, Toolbar, Typography } from '@mui/material';
import React, { useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import IconCheck from '@/assets/icon-check.svg';
import IconChevronLeft from '@/assets/icon-chevron-left.svg';
import IconChevronRight from '@/assets/icon-chevron-right.svg';
import IconClose from '@/assets/icon-close.svg';
import IconLocale from '@/assets/icon-locale.svg';
import IconLogout from '@/assets/icon-logout.svg';
import IconMetamask from '@/assets/icon-metamask.png';
import IconQubic from '@/assets/icon-qubic.svg';
import QubicPassLogo from '@/assets/qubic-pass-logo.svg';
import WalletConnectCircleBlue from '@/assets/walletconnect-circle-blue.svg';
import { LOGIN_PATH, SIGNIN_LOGIN_PATH } from '@/constants/routes';
import { useAuth } from '@/contexts/AuthContext';
import { useNavigation } from '@/contexts/NavigationContext';

import { getLocale, LocaleType, saveLocale } from '../../configs/i18n';
import useSnackbar from '../Snackbar';

const LOCALE_NAME: Record<LocaleType, string> = {
  en: 'EN',
  'zh-TW': '中文',
};

const StyledMenuItem = styled(MenuItem)(() => ({
  padding: '16px 24px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

const HorizontalLine = styled(Box)(({ theme: { palette } }) => ({
  width: '100%',
  height: 2,
  backgroundColor: palette.extraLightGray.main,
}));

const AppHeader = React.memo(() => {
  const { options } = useNavigation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [choosingLocale, setChoosingLocale] = useState(false);

  const { t } = useTranslation();

  const handleMenu = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const { showSnackbar } = useSnackbar();

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setChoosingLocale(false);
  }, []);

  const { user } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onBackButtonClick = useCallback(() => {
    navigate(options.backUrl, { replace: true });
  }, [navigate, options.backUrl]);

  const { handleLogOut } = useAuth();
  const logOut = useCallback(() => {
    handleLogOut();
    handleClose();
    navigate(LOGIN_PATH, { replace: true });
  }, [handleLogOut, handleClose, navigate]);

  const copyAddress = useCallback(async () => {
    if (user?.address) {
      await navigator.clipboard.writeText(user.address);
      showSnackbar({ message: t('common.copied'), severity: 'success' });
    }
  }, [showSnackbar, t, user]);

  const locale = getLocale();

  const gotoChooseLocale = useCallback(() => {
    setChoosingLocale(true);
  }, []);

  const leaveChooseLocale = useCallback(() => {
    setChoosingLocale(false);
  }, []);

  const selectLocale = useCallback(
    (l: LocaleType) => {
      saveLocale(l);
      handleClose();
      leaveChooseLocale();
    },
    [leaveChooseLocale, handleClose],
  );

  const leftSection = useMemo(() => {
    const { pathname } = location;
    if (pathname === LOGIN_PATH || pathname === SIGNIN_LOGIN_PATH) return null;

    switch (options.left) {
      case 'logo':
        return <img src={QubicPassLogo} alt="qubic-pass" />;
      case 'back':
        return (
          <IconButton
            onClick={onBackButtonClick}
            sx={{
              width: 34,
              height: 34,
              zIndex: 1,
              paddingLeft: '7px',
              paddingRight: '9px',
            }}
          >
            <img width={18} height={14} src={IconChevronLeft} alt="go-back" />
          </IconButton>
        );
      default:
        return null;
    }
  }, [location, onBackButtonClick, options.left]);

  const walletIconSrc = useMemo(() => {
    switch (user?.walletType) {
      case 'walletconnect':
        return WalletConnectCircleBlue;
      case 'metamask':
        return IconMetamask;
      default:
        return IconQubic;
    }
  }, [user?.walletType]);

  return options.showHeader ? (
    <AppBar
      position="static"
      elevation={0}
      sx={{
        zIndex: 1,
        backgroundColor: options.backgroundColor,
        color: theme => theme.palette.text.primary,
      }}
    >
      <Container
        sx={theme => ({
          [theme.breakpoints.up('sm')]: {
            padding: 0,
          },
        })}
      >
        <Toolbar sx={{ paddingX: 0 }}>
          {leftSection}
          <Typography
            variant="h5"
            component="div"
            textAlign="center"
            sx={{
              flexGrow: 1,
              position: 'absolute',
              left: 0,
              boxSizing: 'border-box',
              width: '100%',
            }}
          >
            {options.title}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          {options.right}
          {options.showRightMenu && (
            <div>
              <IconButton
                size="medium"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                sx={{
                  padding: '4px 8px',
                  borderRadius: '24px',
                  backgroundColor: '#FFF',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Stack paddingRight={1}>
                  <img width={32} height={32} src={walletIconSrc} alt="logo" />
                </Stack>
                <Typography variant="h6">
                  {user?.address?.slice(0, 8)}
                  ...
                  {user?.address?.slice(-4)}
                </Typography>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                PaperProps={{
                  sx: {
                    width: 'calc(100% - 48px)',
                    maxWidth: 375,
                  },
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {choosingLocale ? (
                  <Stack>
                    <StyledMenuItem onClick={leaveChooseLocale}>
                      <Stack marginRight={2}>
                        <img width={18} height={14} src={IconChevronLeft} alt="chevron-left" />
                      </Stack>
                      <Typography variant="h5">{t('common.language')}</Typography>
                    </StyledMenuItem>
                    {Object.entries(LOCALE_NAME).map(([key, value]) => (
                      <React.Fragment key={key}>
                        <HorizontalLine />
                        <StyledMenuItem onClick={() => selectLocale(key as LocaleType)}>
                          <Typography variant="h5">{value}</Typography>
                          {key === locale && (
                            <Stack marginLeft="auto">
                              <img width={18} height={14} src={IconCheck} alt="check" />
                            </Stack>
                          )}
                        </StyledMenuItem>
                      </React.Fragment>
                    ))}
                  </Stack>
                ) : (
                  <Stack>
                    <StyledMenuItem onClick={copyAddress}>
                      <Stack marginRight={2}>
                        <img width={48} height={48} src={walletIconSrc} alt="logo" />
                      </Stack>
                      <Typography
                        variant="h6"
                        sx={{
                          whiteSpace: 'break-spaces',
                          wordBreak: 'break-all',
                        }}
                      >
                        {user?.address}
                      </Typography>
                      <Typography variant="buttonSmall" paddingLeft={2}>
                        {t('common.copy')}
                      </Typography>
                    </StyledMenuItem>
                    <HorizontalLine />
                    <StyledMenuItem onClick={gotoChooseLocale}>
                      <Stack marginRight={2}>
                        <img width={16} height={16} src={IconLocale} alt="locale" />
                      </Stack>
                      <Typography variant="h5">
                        {t('common.language')}
                        &nbsp;:&nbsp;
                        {LOCALE_NAME[locale || 'en']}
                      </Typography>
                      <Stack marginLeft="auto">
                        <img width={8} height={14} src={IconChevronRight} alt="chevron-right" />
                      </Stack>
                    </StyledMenuItem>
                    <HorizontalLine />
                    <StyledMenuItem onClick={logOut}>
                      <Stack marginRight={2}>
                        <img width={16} height={16} src={IconLogout} alt="logout" />
                      </Stack>
                      <Typography variant="h5">{t('common.logout')}</Typography>
                    </StyledMenuItem>
                  </Stack>
                )}
              </Menu>
            </div>
          )}

          {options.showDismissButton && (
            <IconButton onClick={goBack}>
              <img src={IconClose} alt="close" />
            </IconButton>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  ) : null;
});

export default AppHeader;
