import { Buffer } from 'buffer';

const convertStringToHex = (str: string): string => {
  try {
    const bytesArr = new Uint8Array(Buffer.from(str));
    return (
      // eslint-disable-next-line no-bitwise
      `0x${Array.from(bytesArr, byte => `0${(byte & 0xff).toString(16)}`.slice(-2)).join('')}`
    );
  } catch (error) {
    console.error(error);
    return '';
  }
};

export default convertStringToHex;
