import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { track } from './analytic';
import { AnalyticTrackEventType } from './events';

const usePageTracking = (): void => {
  const location = useLocation();

  useEffect(() => {
    track({
      type: AnalyticTrackEventType.PAGE_VIEW,
      properties: {
        path: location.pathname + location.search,
      },
    });
  }, [location]);
};

export default usePageTracking;
