import { API_URL } from './env';

export const QUBIC_AUTH_URL = `${API_URL}/services/auth/qubic`;
export const OTHER_AUTH_URL = `${API_URL}/services/auth`;
export const API_GQL_URL = `${API_URL}/services/graphql`;
export const API_GQL_PUBLIC_URL = `${API_URL}/services/graphql-public`;
export const MAX_WIDTH = 480;
export const HIDE_DURATION_MS = 6000;

export const HIDE_QRCODE_CONTRACTS = ['0xBF527ba7c4Ba0D876d9B2536863142618fC34614'];

export type WalletType = 'metamask' | 'qubic' | 'walletconnect';
export type ParamWalletType = WalletType; // in param walletconnect means both walletconnect and walletconnect v2

export const WALLET_CONNECT_PROJECT_ID = 'edbf436324abdbfd2d60c437c322fb27'; // all env use same project id, so put it in config
export const WALLET_CONNECT_METADATA = {
  description: 'Qubic Pass',
  url: 'https://pass.qubic.app',
  icons: ['https://pass.qubic.app/apple-touch-icon.png'],
  name: 'Qubic Pass',
};

export const DEFAULT_MEDIA_VIEWER_SIZE = '22.5rem';
