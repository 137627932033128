const GOOGLE_ANALYTIC_MEASUREMENT_ID = process.env.REACT_APP_GOOGLE_ANALYTIC_MEASUREMENT_ID as string;
const MIXPANEL_ID = process.env.REACT_APP_MIXPANEL_ID as string;
const QUBIC_INFURA_PROJECT_ID = process.env.REACT_APP_QUBIC_INFURA_PROJECT_ID as string;
const QUBIC_API_KEY = process.env.REACT_APP_QUBIC_API_KEY as string;
const QUBIC_API_SECRET = process.env.REACT_APP_QUBIC_API_SECRET as string;
const QUBIC_WALLET_URL = process.env.REACT_APP_QUBIC_WALLET_URL as string;
const API_URL = process.env.REACT_APP_API_URL as string;
const API_KEY = process.env.REACT_APP_API_KEY as string;
const API_SECRET = process.env.REACT_APP_API_SECRET as string;

if (!GOOGLE_ANALYTIC_MEASUREMENT_ID) {
  throw Error('env variable GOOGLE_ANALYTIC_MEASUREMENT_ID not found');
}

if (!MIXPANEL_ID) {
  throw Error('env variable MIXPANEL_ID not found');
}

if (!QUBIC_API_KEY) {
  throw Error('env variable QUBIC_API_KEY not found');
}

if (!QUBIC_API_SECRET) {
  throw Error('env variable QUBIC_API_SECRET not found');
}

if (!QUBIC_INFURA_PROJECT_ID) {
  throw Error('env variable INFURA_PROJECT_ID not found');
}

if (!API_URL) {
  throw Error('env variable API_URL not found');
}

if (!API_KEY) {
  throw Error('env variable API_KEY not found');
}

if (!API_SECRET) {
  throw Error('env variable API_SECRET not found');
}

// private variable injected by config-overrides.js, not from .env files.
const APP_VERSION = process.env.APP_VERSION || '';
const GIT_COMMIT_SHA_SHORT = process.env.GIT_COMMIT_SHA_SHORT || '';
const GIT_COMMIT_DATE = process.env.GIT_COMMIT_DATE || '';

export {
  GOOGLE_ANALYTIC_MEASUREMENT_ID,
  MIXPANEL_ID,
  QUBIC_API_KEY,
  QUBIC_API_SECRET,
  QUBIC_INFURA_PROJECT_ID,
  API_URL,
  API_KEY,
  API_SECRET,
  QUBIC_WALLET_URL,
  APP_VERSION,
  GIT_COMMIT_SHA_SHORT,
  GIT_COMMIT_DATE,
};
