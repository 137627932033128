import { Alert, AlertProps, Snackbar } from '@mui/material';
import React, { useState, createContext, useMemo, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { HIDE_DURATION_MS } from '@/constants/config';

interface Props {
  children: React.ReactNode;
}

interface showSnackbarInput {
  message: string;
  severity: AlertProps['severity'];
}

interface ContextValue {
  showSnackbar: (input: showSnackbarInput) => void;
}

export const SnackbarContext = createContext<ContextValue>({
  showSnackbar: () => null,
});

export const SnackbarProvider = React.memo<Props>(props => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState<AlertProps['severity']>('success');

  const showSnackbar = useCallback((input: showSnackbarInput) => {
    setMessage(input.message);
    setSeverity(input.severity);
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const contextValue = useMemo(() => {
    return { showSnackbar };
  }, [showSnackbar]);

  return (
    <SnackbarContext.Provider value={contextValue}>
      <Snackbar
        open={open}
        autoHideDuration={HIDE_DURATION_MS}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      {props.children}
    </SnackbarContext.Provider>
  );
});

const useSnackbar = (): ContextValue => {
  const { showSnackbar } = useContext(SnackbarContext);
  return {
    showSnackbar,
  };
};

export const useHandleError = (): ((reason?: string) => void) => {
  const { showSnackbar } = useContext(SnackbarContext);
  const { t } = useTranslation();
  const handleError = useCallback(
    (reason?: string) => showSnackbar({ message: reason || t('error.general_error'), severity: 'error' }),
    [showSnackbar, t],
  );

  return handleError;
};

export default useSnackbar;
