export enum AnalyticTrackEventType {
  PAGE_VIEW = 'PAGE_VIEW',
  SIGN_IN = 'SIGN_IN',
  ACTIVATE_WALLET = 'ACTIVATE_WALLET',
  SELECT_MERCHANT = 'SELECT_MERCHANT',
  SELECT_COLLECTIBLE = 'SELECT_COLLECTIBLE',
  VERIFY_COLLECTIBLE = 'VERIFY_COLLECTIBLE',
}

export type AnalyticTrackEvent =
  | {
      type: AnalyticTrackEventType.PAGE_VIEW;
      properties: {
        path: string;
        trackerNames?: string;
        title?: string;
      };
    }
  | {
      type: AnalyticTrackEventType.SIGN_IN;
      properties: {
        accountAddress: string;
        isQubicUser: boolean;
      };
    }
  | {
      type: AnalyticTrackEventType.ACTIVATE_WALLET;
      properties: {
        accountAddress: string;
        isQubicUser: boolean;
      };
    }
  | {
      type: AnalyticTrackEventType.SELECT_MERCHANT;
      properties: {
        accountAddress: string;
        merchant: string;
      };
    }
  | {
      type: AnalyticTrackEventType.SELECT_COLLECTIBLE;
      properties: {
        accountAddress: string;
        contractAddress: string;
        tokenId: string;
        chainId: number;
        merchantName: string;
      };
    }
  | {
      type: AnalyticTrackEventType.VERIFY_COLLECTIBLE;
      properties:
        | {
            // currently verify collectible api has no collectible owner accountAddress
            // accountAddress: string;
            contractAddress: string;
            tokenId: string;
            chainId: number;
            merchantName: string;
          }
        | {
            error: string;
          };
    };
