import { WalletType } from '@/constants/config';
import { STORAGE_KEYS } from '@/constants/storage';

export interface AuthData {
  accessToken: string;
  expiredAt: number;
  address: string;
  walletType: WalletType;
}

export function getAuthDataFromStorage(): AuthData | null {
  const authData = localStorage.getItem(STORAGE_KEYS.QUBIC_PASS_AUTH);

  return authData ? (JSON.parse(authData) as AuthData) : null;
}

export function setAuthDataToStorage(authData: AuthData): void {
  localStorage.setItem(STORAGE_KEYS.QUBIC_PASS_AUTH, JSON.stringify(authData));
}

export function checkIfAuthDataExpired(): boolean {
  const authData = getAuthDataFromStorage();

  return authData ? authData.expiredAt * 1000 < Date.now() : true;
}
