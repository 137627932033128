import { LinearProgress } from '@mui/material';
import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';

import { BasicLayout } from '@/components/BasicLayout';
import { RequiredLogin } from '@/components/RequiredLogin';
import {
  LOGIN_PATH,
  HOME_PATH,
  COLLECTIBLES_DETAIL_PATH,
  VERIFY_COLLECTIBLE_TICKET_PATH,
  SCAN_QRCODE_PATH,
  VERIFY_LOGIN_PATH,
  SIGNIN_LOGIN_PATH,
  VERIFY_CALLBACK_PATH,
  SIGNIN_CALLBACK_PATH,
  COLLECTIBLE_REDEEM_PATH,
  SDK_AUTH_PATH,
  MAINTENANCE_PATH,
} from '@/constants/routes';
import UnderMaintenanceScreen from '@/screens/Maintenance';
import { usePageTracking } from '@/utils/analytic';
import { useUnderMaintenanceHandler } from '@/utils/useUnderMaintenanceHandler';

const HomeScreen = React.lazy(() => import('./screens/Home'));
const LoginScreen = React.lazy(() => import('./screens/Login'));
const AuthScreen = React.lazy(() => import('./screens/Auth'));
const VerifyCollectibleScreen = React.lazy(() => import('./screens/VerifyCollectible'));
const ScanQrCodeScreen = React.lazy(() => import('./screens/ScanQrCode'));
const VerifyCallbackScreen = React.lazy(() => import('./screens/VerifyCallback'));
const CollectibleDetailScreen = React.lazy(() => import('./screens/CollectibleDetail'));
const CollectibleRedeemScreen = React.lazy(() => import('./screens/CollectibleRedeem'));

const Main = React.memo(() => {
  usePageTracking();
  useUnderMaintenanceHandler();

  // expose navigate function for pass tab in qubic wallet
  const navigate = useNavigate();
  useEffect(() => {
    (window as any).goHome = () => {
      navigate('/home', { replace: true });
      window.scrollTo(0, 0);
    };
  }, [navigate]);

  return (
    <React.Suspense fallback={<LinearProgress />}>
      <Routes>
        <Route path="/" element={<BasicLayout />}>
          <Route path={LOGIN_PATH} element={<LoginScreen mode="login" />} />
          <Route
            path={HOME_PATH}
            element={
              <RequiredLogin>
                <HomeScreen />
              </RequiredLogin>
            }
          />
          <Route
            path={COLLECTIBLES_DETAIL_PATH}
            element={
              <RequiredLogin>
                <CollectibleDetailScreen />
              </RequiredLogin>
            }
          />
          <Route
            path={COLLECTIBLE_REDEEM_PATH}
            element={
              <RequiredLogin>
                <CollectibleRedeemScreen />
              </RequiredLogin>
            }
          />
          <Route path={VERIFY_COLLECTIBLE_TICKET_PATH} element={<VerifyCollectibleScreen />} />
          <Route path={SCAN_QRCODE_PATH} element={<ScanQrCodeScreen />} />
          <Route path={VERIFY_LOGIN_PATH} element={<LoginScreen mode="nft-verify" />} />
          <Route path={SIGNIN_LOGIN_PATH} element={<LoginScreen mode="address-sign" />} />
          <Route path={VERIFY_CALLBACK_PATH} element={<VerifyCallbackScreen />} />
          <Route path={SIGNIN_CALLBACK_PATH} element={<VerifyCallbackScreen signOnly />} />

          {/* SDK_AUTH_PATH is for customer to fast login  */}
          <Route path={SDK_AUTH_PATH} element={<AuthScreen />} />

          <Route path={MAINTENANCE_PATH} element={<UnderMaintenanceScreen />} />

          <Route path="/" element={<Navigate to={LOGIN_PATH} replace />} />
        </Route>
      </Routes>
    </React.Suspense>
  );
});

export default Main;
