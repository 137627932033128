import { AnalyticTrackEvent, AnalyticTrackEventType } from './events';
import gtag from './gtag';
import mixpanel from './mixpanel';

export const track = (event: AnalyticTrackEvent): void => {
  if (event.type === AnalyticTrackEventType.PAGE_VIEW) {
    gtag('event', 'page_view', { page_path: event.properties.path });
  } else {
    gtag('event', event.type, event.properties);
  }
  mixpanel.track(event.type, event.properties);
};
