import { TypedEventEmitter } from './TypedEventEmitter';

type ApiNetworkErrorEventTypes = {
  NETWORK_ERROR: [
    number, // statusCode
    unknown, // axiosError, apollo network error
  ];
};

export const apiNetworkErrorEmitter = new TypedEventEmitter<ApiNetworkErrorEventTypes>();
