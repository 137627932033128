import { Box, Typography, SxProps, Theme } from '@mui/material';

import { fullVersion } from '@/utils/version';

type FooterProps = {
  sx?: SxProps<Theme>;
};

export default function Footer({ sx }: FooterProps): JSX.Element {
  return (
    <Box sx={sx}>
      <Typography
        variant="caption"
        color="darkGray.main"
        sx={{
          opacity: 0.5,
          transition: 'opacity 2s',
          '&:hover': {
            opacity: 1,
          },
        }}
      >
        v{fullVersion}
      </Typography>
    </Box>
  );
}
