export const VERIFY_COLLECTIBLE_TICKET = 'verify-collectible-ticket';

export const LOGIN_PATH = '/login';
export const HOME_PATH = '/home';
export const COLLECTIBLES_DETAIL_PATH = '/collectibles/:chainId/:contractAddress/:tokenId';
export const VERIFY_COLLECTIBLE_TICKET_PATH = `/${VERIFY_COLLECTIBLE_TICKET}/:ticket`;
export const COLLECTIBLE_REDEEM_PATH = `/redeem`;
export const SCAN_QRCODE_PATH = '/scan-qr-code';
export const VERIFY_LOGIN_PATH = '/verify';
export const SDK_AUTH_PATH = '/auth';
// new flow other than origin webhook verify
export const SIGNIN_LOGIN_PATH = '/signin';
export const VERIFY_CALLBACK_PATH = '/verify-callback';
export const SIGNIN_CALLBACK_PATH = '/signin-callback';
export const MAINTENANCE_PATH = '/maintenance';

export const getCollectibleDetailPath = (chainId: number, contractAddress: string, tokenId: string): string =>
  `/collectibles/${chainId}/${contractAddress}/${tokenId}`;
export const getVerifyCollectibleTicketPath = (ticket: string): string => `/${VERIFY_COLLECTIBLE_TICKET}/${ticket}`;
