import qs from 'query-string';
import React, { createContext, useContext, useMemo } from 'react';

interface DetectAppPassTabContextValue {
  isInAppPassTab: boolean;
}

export const DetectAppPassTabContext = createContext<DetectAppPassTabContextValue>({
  isInAppPassTab: false,
});

interface Props {
  children: React.ReactNode;
}

// for local development, we can use this url
// location.href = 'http://localhost:3000?isInAppPassTab=true'
export const DetectAppPassTabProvider = React.memo<Props>(props => {
  const contextValue = useMemo(() => {
    const qsParsed = qs.parse(window.location.search);
    const isInAppPassTab = qsParsed.isInAppPassTab === 'true';
    return {
      isInAppPassTab,
    };
  }, []);

  return <DetectAppPassTabContext.Provider value={contextValue}>{props.children}</DetectAppPassTabContext.Provider>;
});

export const useDetectAppPassTab = (): DetectAppPassTabContextValue => {
  return useContext(DetectAppPassTabContext);
};
