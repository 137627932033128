import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';

import { LOGIN_PATH } from '@/constants/routes';
import { useAuth } from '@/contexts/AuthContext';
import { useDetectAppPassTab } from '@/contexts/DetectAppPassTabContext';
import { useWallet } from '@/contexts/WalletContext';

import { useHandleError } from './Snackbar';

interface Props {
  children?: React.ReactNode | null;
}

export const RequiredLogin = React.memo<Props>(props => {
  const { user } = useAuth();
  const { activate } = useWallet();
  const location = useLocation();
  const handleError = useHandleError();

  const isNotLogin = user === null;

  const { t } = useTranslation();

  useEffect(() => {
    if (user) {
      activate(user.walletType, { isConnectEagerly: true });
    }
  }, [user, activate]);

  const { isInAppPassTab } = useDetectAppPassTab();

  useEffect(() => {
    // isInAppPassTab will automatically login again no need to show error to user
    if (isNotLogin && !isInAppPassTab) {
      handleError(t('error.login_expired'));
    }
  }, [handleError, isInAppPassTab, isNotLogin, t]);

  if (isNotLogin) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={LOGIN_PATH} state={{ from: location }} replace />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{props.children}</>;
});
