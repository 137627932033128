import { Box, Container, Link, Stack, styled, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import IconFacebook from '@/assets/icon-facebook.svg';
import IconInstagram from '@/assets/icon-instagram.svg';
import Version from '@/components/Version';
import { useNavigation } from '@/contexts/NavigationContext';

const SocialButton = styled(Link)(() => ({
  borderRadius: 32,
  width: 32,
  height: 32,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '& img': {
    width: 16,
    height: 16,
  },
}));

const AppFooter = React.memo(() => {
  const { options } = useNavigation();
  const { palette } = useTheme();
  const { t } = useTranslation();

  if (!options.showFooter) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'relative',
        backgroundColor: 'black',
        paddingTop: 4,
        paddingBottom: 4,
        color: 'white',
        marginTop: {
          xs: 0,
          md: '32px',
        },
      }}
    >
      <Container>
        <Stack>
          <Stack
            sx={{
              flexDirection: {
                xs: 'column',
                md: 'row',
              },
              justifyContent: {
                xs: 'flex-start',
                md: 'space-around',
              },
              alignItems: {
                xs: 'center',
                md: 'flex-start',
              },
            }}
            spacing={{ xs: 4, md: 0 }}
          >
            <Stack direction="column" spacing={2}>
              <Typography variant="h6" color={palette.white.main} margin={0}>
                Company
              </Typography>
              <Link href="https://www.maicoin.com/zh-TW/about" target="_blank" rel="noopener">
                <Typography variant="bodySmall" color={palette.white.main}>
                  {t('common.maicoin_group')}
                </Typography>
              </Link>
              <Link href="https://www.qubic.app" target="_blank" rel="noopener">
                <Typography variant="bodySmall" color={palette.white.main}>
                  Qubic Wallet
                </Typography>
              </Link>
              <Link href="https://creator.qubic.market" target="_blank" rel="noopener">
                <Typography variant="bodySmall" color={palette.white.main}>
                  Qubic Creator
                </Typography>
              </Link>
            </Stack>
            <Stack direction="column" spacing={2}>
              <Typography variant="h6" color={palette.white.main}>
                {t('common.instructions')}
              </Typography>
              <Link href="https://www.qubic.app/assets/Qubic_FAQ.pdf" target="_blank" rel="noopener">
                <Typography variant="bodySmall" color={palette.white.main}>
                  {t('common.faq')}
                </Typography>
              </Link>
              <Link href="mailto:hello@qubic.app" target="_blank" rel="noopener">
                <Typography variant="bodySmall" color={palette.white.main}>
                  {t('common.contact_us')}
                </Typography>
              </Link>
            </Stack>
            <Stack direction="column" spacing={2}>
              <Typography variant="h6" color={palette.white.main}>
                Community
              </Typography>
              <Stack direction="row" spacing={1} justifyContent="center">
                <SocialButton href="https://www.facebook.com/QubicWallet" target="_blank" rel="noopener">
                  <img src={IconFacebook} alt="facebook" />
                </SocialButton>
                <SocialButton href="https://www.instagram.com/qubic_nft" target="_blank" rel="noopener">
                  <img src={IconInstagram} alt="instagram" />
                </SocialButton>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction="column" spacing={1}>
            <Typography variant="caps" color={palette.gray.main} sx={{ paddingTop: 4, paddingBottom: 2 }}>
              © AMIS Technologies Co., Ltd.
            </Typography>
          </Stack>
        </Stack>
      </Container>
      <Version
        sx={{
          position: 'absolute',
          bottom: 4,
          right: 8,
        }}
      />
    </Box>
  );
});

export default AppFooter;
