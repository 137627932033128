import { ExternalProvider } from '@ethersproject/providers';
import { SignInProvider } from '@qubic-js/core';
import WalletConnectProvider from '@walletconnect/ethereum-provider';
import { Provider } from '@web3-react/types';
import noop from 'lodash/noop';
import React, { ReactNode, useMemo, createContext, useContext, useCallback, useEffect, useState, useRef } from 'react';

import { WalletType } from '@/constants/config';
import { API_URL } from '@/constants/env';
import { STORAGE_KEYS } from '@/constants/storage';

import { track } from '../utils/analytic';
import { AnalyticTrackEventType } from '../utils/analytic/events';
import { checkIfAuthDataExpired, getAuthDataFromStorage, setAuthDataToStorage } from '../utils/auth';
import convertStringToHex from '../utils/convertStringToHex';
import signIn from '../utils/signIn';

import { useDetectAppPassTab } from './DetectAppPassTabContext';
import { ActivatedWalletResponse, useWallet } from './WalletContext';

// issue token expired time
// prod/stag: 2 hr
// dev: 1d
const BEFORE_TIMEOUT_MS = 10 * 60 * 1000; // ten minutes
const ISSUE_TOKEN_INTERVAL_MS = 2 * 60 * 60 * 1000; // two hours

export const isMobile = typeof navigator !== 'undefined' && /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

interface LoginParams {
  accountAddress: string | null;
  signature: string;
  dataString: string;
  isQubicUser: boolean;
}

interface RequestArguments {
  jsonrpc: string;
  method: string;
  params: string[];
}

export interface UserData {
  accessToken: string;
  expiredAt: number;
  address: string;
  walletType: WalletType;
}

interface ContextProps {
  user: UserData | undefined | null;
  activateWalletAndSign: (
    type: WalletType,
    options: { enableLogin: boolean; dataString?: string; qubicSignInProvider?: SignInProvider },
  ) => Promise<LoginParams>;
  handleLogOut: () => void;
  isLoggingIn: boolean;
  isSigningMessage: boolean;
  showConfirmAuthSignDialog: boolean;
  isConfirmAuthLoading: boolean;
  closeAuthSign: () => void;
  confirmAuthSign: () => void;
}

export type QubicProvider = Provider & { isQubic?: boolean; hide: () => void };

export const AuthContext = createContext<ContextProps>({
  user: undefined,
  activateWalletAndSign: async () =>
    new Promise(() => {
      // do nothing
    }),
  handleLogOut: () => {
    // do nothing
  },
  isLoggingIn: true,
  isSigningMessage: false,
  showConfirmAuthSignDialog: false,
  isConfirmAuthLoading: true,
  closeAuthSign: () => null,
  confirmAuthSign: () => null,
});

export const AuthConnectorsProvider = React.memo(({ children }: { children?: ReactNode }) => {
  const { ethersProvider, account, walletType, activate, deactivate, isActivating, connector } = useWallet();

  const { isInAppPassTab } = useDetectAppPassTab();

  const getAuthFromRecover = useCallback(() => {
    try {
      return getAuthDataFromStorage();
    } catch (e) {
      return null;
    }
  }, []);

  const [user, setUser] = useState<UserData | null>(getAuthFromRecover);

  const paramsRef = useRef({
    enableLogin: false,
    dataString: '',
  });

  const activeWalletPromiseRef = useRef<{
    resolve: (value: LoginParams) => void;
    reject: (error: Error) => void;
  }>();

  const handleLogOut = useCallback(() => {
    if (connector) deactivate(connector);
    setUser(null);
    localStorage.removeItem(STORAGE_KEYS.QUBIC_PASS_AUTH);
  }, [connector, deactivate]);

  const setupAuthData = useCallback(
    (activatedWalletResponse: ActivatedWalletResponse, authUser: Partial<UserData>) => {
      if (!authUser) {
        return;
      }

      const { accessToken, expiredAt } = authUser;

      if (!accessToken || !expiredAt) {
        console.error('Missing accessToken or expiredAt', accessToken, expiredAt);
        handleLogOut();
        return;
      }

      const refinedUser: UserData = {
        address: activatedWalletResponse.account,
        walletType: activatedWalletResponse.walletType,
        accessToken,
        expiredAt,
      };

      track({
        type: AnalyticTrackEventType.SIGN_IN,
        properties: {
          accountAddress: activatedWalletResponse.account,
          isQubicUser: activatedWalletResponse.walletType === 'qubic' || false,
        },
      });

      setUser(refinedUser);

      setAuthDataToStorage(refinedUser);
    },
    [handleLogOut],
  );

  const signByWeb3Provider = useCallback(
    async (activatedWalletResponse: ActivatedWalletResponse, payload: RequestArguments): Promise<string> => {
      if (!activatedWalletResponse.account || !payload) {
        throw Error('Missing provider account or request payload');
      }

      try {
        const currentProvider = activatedWalletResponse.connector.provider;
        if (!currentProvider) throw Error('no provider');
        const isQubic = Boolean((currentProvider as QubicProvider)?.isQubic);

        const keyOrSignature = currentProvider?.request ? await currentProvider.request(payload) : '';

        // When user reject or close qubic-wallet, will get Error as return value
        if (typeof keyOrSignature !== 'string' && !Array.isArray(keyOrSignature)) {
          throw keyOrSignature;
        }

        const selectedSignature = isQubic ? keyOrSignature[0] : keyOrSignature;
        return selectedSignature;
      } catch (error: any) {
        // Metamask and Qubic wallet use same Web3Provider context.
        // Once user sign in with Qubic, the provider will keep `isQubic` props in the context.
        // so we need to deactivate the context when user reject or close dialog.
        deactivate(activatedWalletResponse.connector);
        if (error instanceof Error) {
          throw error;
        }
        // it seems request() doesn't throw error with Error class
        if ('message' in error) {
          throw new Error(error.message);
        }
        throw new Error(`unknown issue:${JSON.stringify(error)}`);
      }
    },
    [deactivate],
  );

  const [isSigningMessage, setIsSigningMessage] = useState(false);
  const handleLogin = useCallback(
    async (activatedWalletResponse: ActivatedWalletResponse) => {
      setIsSigningMessage(true);
      try {
        const dataString =
          paramsRef.current.dataString ||
          JSON.stringify({
            name: 'Qubic_Pass',
            url: API_URL,
            permissions: ['wallet.permission.access_email_address'],
            nonce: Date.now(),
            service: 'qubee-creator-qubeepass',
          });

        const currentProvider = activatedWalletResponse.connector.provider as QubicProvider;
        const isQubic = Boolean(currentProvider?.isQubic);

        const payload: RequestArguments = isQubic
          ? {
              jsonrpc: '2.0',
              method: 'qubic_issueIdentityTicket',
              params: [],
            }
          : {
              jsonrpc: '2.0',
              method: 'personal_sign',
              params: [convertStringToHex(dataString), activatedWalletResponse.account],
            };
        const authSignature = await signByWeb3Provider(activatedWalletResponse, payload);

        if (authSignature && paramsRef.current.enableLogin) {
          const authData = await signIn({
            accountAddress: activatedWalletResponse.account,
            signature: authSignature,
            dataString,
            isQubic,
          });
          setupAuthData(activatedWalletResponse, authData);
        }

        if (authSignature) {
          activeWalletPromiseRef.current?.resolve({
            accountAddress: activatedWalletResponse.account,
            signature: authSignature,
            dataString,
            isQubicUser: isQubic,
          });
          activeWalletPromiseRef.current = undefined;
        }

        if (currentProvider?.isQubic) {
          currentProvider?.hide?.();
        }
      } catch (error) {
        activeWalletPromiseRef.current?.reject(error as any);
        activeWalletPromiseRef.current = undefined;
      }

      setIsSigningMessage(false);
    },
    [setupAuthData, signByWeb3Provider],
  );

  const [showConfirmAuthSignDialog, setConfirmShowAuthSignDialog] = useState(false);

  const activateWalletAndSign = useCallback<ContextProps['activateWalletAndSign']>(
    (type, { enableLogin, dataString = '', qubicSignInProvider }) => {
      return new Promise<LoginParams>((resolve, reject) => {
        if (activeWalletPromiseRef.current) {
          // clear previous one
          activeWalletPromiseRef.current?.reject(new Error('Previous action not finished'));
          activeWalletPromiseRef.current = undefined;
        }
        activeWalletPromiseRef.current = {
          resolve,
          reject,
        };
        paramsRef.current = {
          enableLogin,
          dataString,
        };
        activate(type, { qubicSignInProvider })
          .then(activatedWalletResponse => {
            if (type === 'walletconnect' && isMobile) {
              setConfirmShowAuthSignDialog(true);
              return;
            }
            handleLogin(activatedWalletResponse);
          })
          .catch(error => {
            activeWalletPromiseRef.current?.reject(error);
            activeWalletPromiseRef.current = undefined;
          });
      });
    },
    [activate, handleLogin],
  );

  useEffect(() => {
    if (account && user?.address && user.address !== account) {
      handleLogOut();
    }
  }, [account, handleLogOut, user?.address]);

  useEffect(() => {
    if (!connector) return noop;

    if (isInAppPassTab && user?.walletType === 'qubic' && account) {
      const intervalId = window.setInterval(() => {
        handleLogin({ account, walletType: 'qubic', connector });
      }, ISSUE_TOKEN_INTERVAL_MS - BEFORE_TIMEOUT_MS);
      return () => {
        window.clearInterval(intervalId);
      };
    }
    return noop;
  }, [account, connector, handleLogin, isInAppPassTab, user?.walletType]);

  useEffect(() => {
    if (user && checkIfAuthDataExpired()) {
      handleLogOut();
    }
  }, [handleLogOut, user]);

  const providerValue = useMemo(
    () => ({
      user,
      activateWalletAndSign,
      handleLogOut,
      isLoggingIn: isActivating || showConfirmAuthSignDialog || isSigningMessage,
      isSigningMessage,
      showConfirmAuthSignDialog,
      isConfirmAuthLoading: !ethersProvider || !account,
      closeAuthSign: () => {
        const maybeWalletConnectProvider = ethersProvider?.provider as WalletConnectProvider | ExternalProvider;
        if ('isWalletConnect' in maybeWalletConnectProvider && maybeWalletConnectProvider.isWalletConnect) {
          maybeWalletConnectProvider.disconnect();
        }
        setConfirmShowAuthSignDialog(false);
        activeWalletPromiseRef.current?.reject(new Error('User cancelled'));
        activeWalletPromiseRef.current = undefined;
        setIsSigningMessage(false);
      },
      confirmAuthSign: () => {
        if (account && walletType && connector) {
          setConfirmShowAuthSignDialog(false);
          handleLogin({ account, walletType, connector });
        }
      },
    }),
    [
      account,
      activateWalletAndSign,
      connector,
      ethersProvider,
      handleLogOut,
      handleLogin,
      isActivating,
      isSigningMessage,
      showConfirmAuthSignDialog,
      user,
      walletType,
    ],
  );

  return <AuthContext.Provider value={providerValue}>{children}</AuthContext.Provider>;
});

// user states:
// initial   -> reload -> login            -> logout
// undefined -> {}     -> { expiredAt... } -> null
export const useAuth = (): ContextProps => {
  const authContext = useContext(AuthContext);

  return authContext;
};
