import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { MAINTENANCE_PATH } from '@/constants/routes';

import { apiNetworkErrorEmitter } from './apiNetworkErrorEmitter';

export const useUnderMaintenanceHandler = (): void => {
  const [isUnderMaintenance, setIsUnderMaintenance] = useState(false);

  useEffect(() => {
    function handle503Error(statusCode: number) {
      if (statusCode === 503) {
        setIsUnderMaintenance(true);
      }
    }
    apiNetworkErrorEmitter.on('NETWORK_ERROR', handle503Error);
    return () => {
      apiNetworkErrorEmitter.off('NETWORK_ERROR', handle503Error);
    };
  }, []);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isUnderMaintenance) {
      navigate(MAINTENANCE_PATH, { replace: true });
    }
  }, [isUnderMaintenance, location.pathname, navigate]);
};
