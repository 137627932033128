import { Box, Button, Container, Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ImgUnderMaintenance from '@/assets/img-under-maintenance.svg';
import { HOME_PATH } from '@/constants/routes';
import { useNavigation } from '@/contexts/NavigationContext';

const SUPPORT_WEB_URL = 'https://qubic.freshdesk.com/support/solutions/69000258772';

const UnderMaintenanceScreen = React.memo(() => {
  const { setOptions } = useNavigation();
  const navigate = useNavigate();

  useEffect(() => {
    setOptions({
      left: 'logo',
      showFooter: true,
    });
  }, [setOptions]);

  const { t } = useTranslation();

  const handleReload = useCallback(() => {
    navigate(HOME_PATH, { replace: true });
  }, [navigate]);

  return (
    <Container
      sx={theme => ({
        minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
        [theme.breakpoints.up('xs')]: {
          padding: 0,
        },
        backgroundColor: theme.palette.white.main,
      })}
    >
      <Stack justifyContent="center" alignItems="center" flex={1}>
        <Box paddingBottom={2}>
          <img
            src={ImgUnderMaintenance}
            style={{
              width: 240,
              height: 240,
            }}
            alt="go"
          />
        </Box>
        <Typography variant="h3" paddingBottom={1}>
          {t('error.upgrade_service')}
        </Typography>
        <Typography variant="body1" paddingBottom={3} textAlign="center">
          {t('error.under_maintenance_info')}
        </Typography>
        <Stack gap={2}>
          <a href={SUPPORT_WEB_URL} style={{ textDecoration: 'none' }} rel="noopener noreferrer">
            <Button variant="outlined">{t('error.check_maintenance_time')}</Button>
          </a>

          <Button variant="text" onClick={handleReload}>
            {t('common.reload')}
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
});

export default UnderMaintenanceScreen;
