import EventEmitter from 'events';

export class TypedEventEmitter<TEvents extends Record<string, any>> {
  private emitter = new EventEmitter();

  emit<TEventName extends keyof TEvents & string>(eventName: TEventName, ...eventArg: TEvents[TEventName]): boolean {
    return this.emitter.emit(eventName, ...(eventArg as []));
  }

  on<TEventName extends keyof TEvents & string>(
    eventName: TEventName,
    handler: (...eventArg: TEvents[TEventName]) => void,
  ): TypedEventEmitter<TEvents> {
    return this.emitter.on(eventName, handler as any) as any as TypedEventEmitter<TEvents>;
  }

  off<TEventName extends keyof TEvents & string>(
    eventName: TEventName,
    handler: (...eventArg: TEvents[TEventName]) => void,
  ): TypedEventEmitter<TEvents> {
    return this.emitter.off(eventName, handler as any) as any as TypedEventEmitter<TEvents>;
  }
}
