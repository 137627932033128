import { Network } from '@qubic-js/core';

export const REQUIRED_SUPPORTED_CHAIN_IDS = [Network.MAINNET];

export const OPTIONAL_SUPPORTED_CHAIN_IDS = [
  Network.HOLESKY,
  Network.POLYGON,
  Network.AMOY,
  Network.BSC,
  Network.BSC_TESTNET,
];
