import axios, { AxiosRequestHeaders } from 'axios';
import querystring from 'query-string';

import { OTHER_AUTH_URL, QUBIC_AUTH_URL, WalletType } from '@/constants/config';
import { API_KEY, API_SECRET } from '@/constants/env';

import { apiNetworkErrorEmitter } from './apiNetworkErrorEmitter';
import convertStringToHex from './convertStringToHex';
import serviceHeaderBuilder from './serviceHeaderBuilder';

export interface SignInProps {
  accountAddress: string | null;
  signature: string;
  dataString: string;
  isQubic: boolean;
}

interface SignInResult {
  accessToken: string;
  expiredAt: number;
  address: string;
  walletType: WalletType;
}

const signIn = async ({ accountAddress, signature, dataString, isQubic }: SignInProps): Promise<SignInResult> => {
  if (!API_KEY || !API_SECRET) {
    throw new Error('No API Auth or client info');
  }

  if (!accountAddress || !signature || (!isQubic && !dataString)) {
    console.error('No data to sign');
    throw new Error('No data to sign');
  }

  const payload = isQubic
    ? querystring.stringify({
        address: accountAddress,
        ticket: signature,
      })
    : querystring.stringify({
        provider: 'wallet',
        address: accountAddress,
        signature,
        data: convertStringToHex(dataString),
      });

  const serviceUri = isQubic ? QUBIC_AUTH_URL : OTHER_AUTH_URL;
  const httpMethod = 'POST';
  const headers = serviceHeaderBuilder({
    serviceUri,
    httpMethod,
    body: payload,
    apiKey: API_KEY,
    apiSecret: API_SECRET,
  }) as AxiosRequestHeaders;

  try {
    const { data } = await axios.post(serviceUri, payload, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        ...headers,
      },
      withCredentials: true,
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      apiNetworkErrorEmitter.emit('NETWORK_ERROR', error.response?.status || 0, error);
    }
    throw error;
  }
};

export default signIn;
