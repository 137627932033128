import qs from 'query-string';
import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { useDetectAppPassTab } from '@/contexts/DetectAppPassTabContext';

import { LocaleType, saveLocale } from '../configs/i18n';

import AppFooter from './Navigation/AppFooter';
import AppHeader from './Navigation/AppHeader';

export const BasicLayout = React.memo(() => {
  const location = useLocation();

  useEffect(() => {
    const qsParsed = qs.parse(location.search);

    saveLocale(qsParsed.locale as LocaleType);
  }, [location.search]);

  const { isInAppPassTab } = useDetectAppPassTab();

  return (
    <>
      {!isInAppPassTab && <AppHeader />}
      <Outlet />
      {!isInAppPassTab && <AppFooter />}
    </>
  );
});
