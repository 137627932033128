import { Web3Provider, ExternalProvider } from '@ethersproject/providers';
import { SignInProvider } from '@qubic-js/core';
import QubicWalletConnector from '@qubic-js/react';
import { useWeb3React } from '@web3-react/core';
import { Connector } from '@web3-react/types';
import { utils } from 'ethers';
import React, { useEffect, ReactNode, useMemo, createContext, useContext, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { WalletType } from '@/constants/config';

import { track } from '../utils/analytic';
import { AnalyticTrackEventType } from '../utils/analytic/events';
import { switchWalletChain } from '../utils/wallet';
import wrappedConnectors from '../utils/wrappedConnectors';

import { useDetectAppPassTab } from './DetectAppPassTabContext';

export interface ActivatedWalletResponse {
  connector: Connector;
  account: string;
  walletType: WalletType;
}

interface ContextProps {
  connector: Connector | null;
  ethersProvider: Web3Provider | undefined;
  account: string | undefined | null;
  walletType?: WalletType;
  activate: (
    type: WalletType,
    options?: { qubicSignInProvider?: SignInProvider; isConnectEagerly?: boolean },
  ) => Promise<ActivatedWalletResponse>;
  deactivate: (connector: Connector) => void;
  signPersonalMessage: (toBeSigned: string, skipPreview: boolean) => Promise<string | null>;
  signTypedData: (message: string, chainId: number) => Promise<string>;
  isActivating: boolean;
}

export type QubicProvider = ExternalProvider & { isQubic?: boolean };

export const WalletContext = createContext<ContextProps>({
  connector: null,
  ethersProvider: undefined,
  walletType: undefined,
  account: undefined,
  activate: async () =>
    new Promise((resolve, reject) => {
      reject();
    }),
  deactivate: () => {
    // no return
  },
  signPersonalMessage: async () =>
    new Promise(resolve => {
      resolve(null);
    }),
  signTypedData: async () =>
    new Promise(resolve => {
      resolve('');
    }),
  isActivating: false,
});

export const WalletProvider = React.memo(({ children }: { children?: ReactNode }) => {
  const { connector, hooks } = useWeb3React();

  const { usePriorityAccount, usePriorityProvider } = hooks;

  const account = usePriorityAccount();
  const ethersProvider = usePriorityProvider();

  const [walletType, setWalletType] = useState<WalletType | undefined>();

  const { isInAppPassTab } = useDetectAppPassTab();
  const [isActivating, setIsActivating] = useState(isInAppPassTab);

  const { t } = useTranslation();

  const activateWallet = useCallback<ContextProps['activate']>(async (_walletType, options) => {
    setIsActivating(true);

    let selectedConnector: Connector | null = null;
    try {
      switch (_walletType) {
        case 'qubic':
          if (options?.qubicSignInProvider) {
            (wrappedConnectors.qubic[0] as QubicWalletConnector).setSignInProvider(options.qubicSignInProvider);
          }
          [selectedConnector] = wrappedConnectors.qubic;
          break;
        case 'metamask':
          [selectedConnector] = wrappedConnectors.metamask;
          break;
        case 'walletconnect':
          [selectedConnector] = wrappedConnectors.walletConnect;
          break;
        default:
      }

      if (!selectedConnector) {
        throw Error('connector not found');
      }

      // qubic has issue with connectEagerly
      if (options?.isConnectEagerly) {
        // failed silently defined by web3-react
        await selectedConnector.connectEagerly?.();
      } else {
        await selectedConnector.activate?.();
      }
      setIsActivating(false);
      setWalletType(_walletType);

      const accounts = (await selectedConnector?.provider?.request({
        method: 'eth_accounts',
      })) as string[];

      if (accounts.length === 0 || !accounts[0]) {
        throw Error('No account');
      }

      const activatedWalletResponse: ActivatedWalletResponse = {
        account: utils.getAddress(accounts[0]),
        connector: selectedConnector,
        walletType: _walletType,
      };
      return activatedWalletResponse;
    } catch (error) {
      setIsActivating(false);
      setWalletType(undefined);
      selectedConnector?.deactivate?.();
      selectedConnector?.resetState();
      throw error;
    }
  }, []);

  const signPersonalMessage = useCallback(
    async (toBeSigned: string, skipPreview?: boolean): Promise<string | null> => {
      if (!ethersProvider) {
        return null;
      }

      let payloadMethod = skipPreview ? 'qubic_skipPreviewSign' : 'personal_sign';

      if (walletType !== 'qubic') {
        payloadMethod = 'personal_sign';
      }

      const payload = {
        jsonrpc: '2.0',
        method: payloadMethod,
        params: [toBeSigned, account],
      };

      try {
        if (!ethersProvider.provider?.request) {
          throw Error(t('error.general_error'));
        }

        const signature = await ethersProvider.provider.request(payload);

        return signature;
      } catch (e) {
        if (e instanceof Error) {
          console.error(e.message);
        }
        throw e;
      }
    },
    [account, ethersProvider, t, walletType],
  );

  const signTypedData = useCallback(
    async (message, chainId) => {
      const currentProvider = ethersProvider?.provider;
      if (!currentProvider || !currentProvider.request) {
        const errorMessage = 'Failed to signTypedData: currentProvider is invalid';
        throw new Error(errorMessage);
      }

      const isWalletChainIdValid = await switchWalletChain(currentProvider, chainId);

      if (!isWalletChainIdValid) {
        const errorMessage = `Failed to switch wallet chainId to target:${chainId}`;
        throw new Error(errorMessage);
      }

      let payloadMethod = 'qubic_skipPreviewSignTypedData';

      if (walletType !== 'qubic') {
        payloadMethod = 'eth_signTypedData_v4';
      }

      const signature = (await currentProvider.request({
        method: payloadMethod,
        params: [account, message],
      })) as string;

      return signature;
    },
    [account, ethersProvider, walletType],
  );

  const deactivate = useCallback((_connector: Connector) => {
    _connector.deactivate?.();
    // clear web3-react state, so priority connector will be reset
    _connector.resetState();
    // cachedActivatedWalletResponseRef.current = null;
    setWalletType(undefined);
  }, []);

  useEffect(() => {
    if (account && walletType) {
      track({
        type: AnalyticTrackEventType.ACTIVATE_WALLET,
        properties: {
          accountAddress: account || '',
          isQubicUser: walletType === 'qubic' || false,
        },
      });
    }
  }, [account, walletType]);

  const providerValue = useMemo(
    () => ({
      connector,
      ethersProvider,
      account,
      walletType,
      activate: activateWallet,
      deactivate,
      signPersonalMessage,
      signTypedData,
      isActivating,
    }),
    [
      connector,
      ethersProvider,
      account,
      walletType,
      activateWallet,
      deactivate,
      signPersonalMessage,
      signTypedData,
      isActivating,
    ],
  );

  return <WalletContext.Provider value={providerValue}>{children}</WalletContext.Provider>;
});

export const useWallet = (): ContextProps => {
  const walletContext = useContext(WalletContext);

  return walletContext;
};
