import { ApolloProvider } from '@apollo/client';
import React, { useContext, useEffect } from 'react';

import { API_KEY, API_SECRET } from '@/constants/env';
import { AuthContext } from '@/contexts/AuthContext';

import { getApolloClient } from './client';

const client = getApolloClient(API_KEY, API_SECRET);

const ApolloContainer = React.memo(({ children }) => {
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (!user && client) {
      client.resetStore();
    }
  }, [user]);

  if (!client) {
    return null;
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
});

export default ApolloContainer;
