import { ThemeOptions } from '@mui/material';

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    bodyLarge: true;
    body: true;
    bodySmall: true;
    buttonLarge: true;
    buttonSmall: true;
    caps: true;
    capsSmall: true;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    mobileMedium: true;
    mobile: true;
  }
  interface TypographyVariants {
    bodyLarge?: React.CSSProperties;
    body?: React.CSSProperties;
    bodySmall?: React.CSSProperties;
    buttonLarge?: React.CSSProperties;
    buttonSmall?: React.CSSProperties;
    caps?: React.CSSProperties;
    capsSmall?: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    bodyLarge?: React.CSSProperties;
    body?: React.CSSProperties;
    bodySmall?: React.CSSProperties;
    buttonLarge?: React.CSSProperties;
    buttonSmall?: React.CSSProperties;
    caps?: React.CSSProperties;
    capsSmall?: React.CSSProperties;
  }
  interface Palette {
    dark: Palette['primary'];
    darkGray: Palette['primary'];
    gray: Palette['primary'];
    lightGray: Palette['primary'];
    extraLightGray: Palette['primary'];
    white: Palette['primary'];
    linkBlue: Palette['primary'];
    mainBgColor: Palette['primary'];
  }
  interface PaletteOptions {
    dark: PaletteOptions['primary'];
    darkGray: PaletteOptions['primary'];

    gray: PaletteOptions['primary'];
    lightGray: PaletteOptions['primary'];
    extraLightGray: PaletteOptions['primary'];
    white: PaletteOptions['primary'];
    linkBlue: PaletteOptions['primary'];
    mainBgColor: PaletteOptions['primary'];
  }
}

export const themeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      mobileMedium: 375,
      mobile: 425,
    },
  },
  palette: {
    dark: {
      main: '#222222',
    },
    darkGray: {
      main: '#6B6A6A',
    },
    gray: {
      main: '#9D9D9D',
    },
    lightGray: {
      main: '#F1F1F1',
    },
    extraLightGray: {
      main: '#F8F8F8',
    },
    white: {
      main: '#FFFFFF',
    },
    error: {
      main: '#F4511E',
    },
    success: {
      main: '#50CD00',
    },
    linkBlue: {
      main: '#2962FF',
    },
    mainBgColor: {
      main: '#F9F9F9',
    },
  },
  typography: {
    h1: {
      fontSize: '32px',
      lineHeight: '48px',
      fontWeight: 600,
    },
    h2: {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: 600,
    },
    h3: {
      fontSize: '20px',
      lineHeight: '28px',
      fontWeight: 600,
    },
    h4: {
      fontSize: '18px',
      lineHeight: '26px',
      fontWeight: 600,
    },
    h5: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 600,
    },
    h6: {
      fontSize: '14px',
      lineHeight: '18px',
      fontWeight: 600,
    },
    bodyLarge: {
      fontSize: '18px',
      lineHeight: '28px',
      fontWeight: 400,
    },
    body: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
    },
    bodySmall: {
      fontSize: '14px',
      lineHeight: '18px',
      fontWeight: 400,
    },
    buttonLarge: {
      fontSize: '18px',
      lineHeight: '32px',
      fontWeight: 500,
    },
    button: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 500,
    },
    buttonSmall: {
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 500,
    },
    caps: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 500,
    },
    capsSmall: {
      fontSize: '10px',
      lineHeight: '14px',
      fontWeight: 500,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          textTransform: 'none',
        },
        sizeLarge: {
          borderRadius: 16,
          paddingTop: 12,
          paddingBottom: 12,
        },
      },
    },
    MuiAvatar: {
      defaultProps: {
        variant: 'rounded',
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 16,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingTop: 32,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 24,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          flex: 1,
          paddingLeft: 24,
          paddingRight: 24,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#222',
        },
      },
      defaultProps: {
        variantMapping: {
          h1: 'p',
          h2: 'p',
          h3: 'p',
          h4: 'p',
          h5: 'p',
          h6: 'p',
          bodyLarge: 'p',
          body: 'p',
          bodySmall: 'p',
          buttonLarge: 'p',
          button: 'p',
          buttonSmall: 'p',
          caps: 'p',
          capsSmall: 'p',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
  },
};
