import { InMemoryCache, defaultDataIdFromObject } from '@apollo/client';
import uniqBy from 'lodash/uniqBy';

const cache = new InMemoryCache({
  dataIdFromObject(responseObject) {
    switch (responseObject.__typename) {
      case 'Collectible': {
        // collectible id becomes `${chainId}-${contractAddress}-${tokenId}-${chainUpdatedTime}-${oneTimeOrder}`
        // need to make cache-id always consistent
        if (!responseObject.id && typeof responseObject.id !== 'string') return defaultDataIdFromObject(responseObject);
        const [chainId, contractAddress, tokenId] = (responseObject.id as string).split('-');
        return `Collectible:${chainId}-${contractAddress}-${tokenId}`;
      }
      default:
        return defaultDataIdFromObject(responseObject);
    }
  },
  typePolicies: {
    Query: {
      fields: {
        listCollectibles: {
          keyArgs: ['input', ['clientId']],
          merge(existing, incoming, { readField }) {
            const existingCollectibles = existing?.collectibles || [];
            const incomingCollectibles = incoming?.collectibles || [];

            const merged = uniqBy([...existingCollectibles, ...incomingCollectibles], c =>
              readField('id', c.collectible),
            );

            return {
              ...incoming,
              collectibles: merged,
            };
          },
        },
      },
    },
  },
});

export default cache;
