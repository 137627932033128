/* eslint-disable react/jsx-boolean-value */
import { createTheme, ThemeProvider } from '@mui/material';
import { Web3ReactProvider } from '@web3-react/core';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { SnackbarProvider } from '@/components/Snackbar';
import { themeOptions } from '@/constants/themeOptions';
import { AuthConnectorsProvider } from '@/contexts/AuthContext';
import { DetectAppPassTabProvider } from '@/contexts/DetectAppPassTabContext';
import { NavigationProvider } from '@/contexts/NavigationContext';
import { VoucherRedeemProvider } from '@/contexts/VoucherRedeemContext';
import { WalletProvider } from '@/contexts/WalletContext';
import '@/configs/i18n';
import { ApolloContainer } from '@/utils/apollo';
import wrappedConnectors from '@/utils/wrappedConnectors';

import './App.css';

import Main from './Main';

const theme = createTheme(themeOptions);

function App(): React.ReactElement {
  return (
    <div className="App">
      <SnackbarProvider>
        <Web3ReactProvider connectors={Object.values(wrappedConnectors)}>
          <DetectAppPassTabProvider>
            <WalletProvider>
              <AuthConnectorsProvider>
                <ApolloContainer>
                  <VoucherRedeemProvider>
                    <BrowserRouter>
                      <ThemeProvider theme={theme}>
                        <NavigationProvider>
                          <Main />
                        </NavigationProvider>
                      </ThemeProvider>
                    </BrowserRouter>
                  </VoucherRedeemProvider>
                </ApolloContainer>
              </AuthConnectorsProvider>
            </WalletProvider>
          </DetectAppPassTabProvider>
        </Web3ReactProvider>
      </SnackbarProvider>
    </div>
  );
}

export default App;
