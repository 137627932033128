import {
  ApolloClient,
  from,
  ServerParseError,
  NormalizedCacheObject,
  createHttpLink,
  ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { print } from 'graphql/language/printer';

import { API_GQL_URL, API_GQL_PUBLIC_URL } from '@/constants/config';
import cache from '@/store/cache';

import { apiNetworkErrorEmitter } from '../apiNetworkErrorEmitter';
import { getAuthDataFromStorage } from '../auth';
import serviceHeaderBuilder from '../serviceHeaderBuilder';

const HTTP_METHOD = 'POST';

const httpLink = createHttpLink({
  uri: API_GQL_URL,
  credentials: 'include',
});

const publicHttpLink = createHttpLink({
  uri: API_GQL_PUBLIC_URL,
  credentials: 'include',
});

const getApiAuthLink = (apiKey: string, apiSecret: string, isPublic: boolean) =>
  setContext(async (request, previousContext) => {
    const { operationName, variables, query } = request;
    const { headers = {} } = previousContext;

    const body =
      operationName && query
        ? JSON.stringify({
            operationName,
            variables,
            query: print(query),
          })
        : undefined;

    const serviceHeaders = serviceHeaderBuilder({
      httpMethod: HTTP_METHOD,
      serviceUri: isPublic ? API_GQL_PUBLIC_URL : API_GQL_URL,
      apiKey,
      apiSecret,
      body,
      accessToken: getAuthDataFromStorage()?.accessToken,
    });

    return {
      headers: {
        ...serviceHeaders,
        ...headers,
      },
    };
  });

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
    );
  }

  if (networkError) {
    apiNetworkErrorEmitter.emit(
      'NETWORK_ERROR',
      'statusCode' in networkError ? networkError?.statusCode : 0,
      networkError,
    );

    // Check if error response is JSON
    try {
      JSON.parse((networkError as ServerParseError)?.bodyText);
    } catch (e) {
      // Handle nginx html response, almost not happened, just in case
      if ((networkError as ServerParseError)?.bodyText?.includes('413 Request Entity Too Large')) {
        networkError.message = 'File exceeds limitation';
      }
    }
  }

  if (networkError) console.error(`[Network error]: ${networkError}`);
});

export const getApolloClient = (apiKey: string, apiSecret: string): ApolloClient<NormalizedCacheObject> => {
  const linkSwitch = ApolloLink.split(
    operation => operation.operationName.startsWith('Public'),
    from([errorLink, getApiAuthLink(apiKey, apiSecret, true), publicHttpLink]),
    from([errorLink, getApiAuthLink(apiKey, apiSecret, false), httpLink]),
  );

  const client = new ApolloClient({
    link: linkSwitch,
    cache,
  });

  return client;
};
