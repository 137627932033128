import { ApolloError, gql, useMutation } from '@apollo/client';
import React, { useState, useCallback, useMemo, createContext, useContext } from 'react';

const VOUCHER_REDEEM = gql`
  mutation VoucherRedeem($requestId: String!, $data: String!, $signature: String!) {
    voucherRedeem(input: { requestId: $requestId, data: $data, signature: $signature }) {
      status
      receipt {
        txHash
        status
        gasUsed
        blockNumber
        blockHash
      }
    }
  }
`;

export interface VoucherRedeemRequest {
  requestId: string;
  data: string;
  signature: string;
}

export interface VoucherRedeemResponse {
  voucherRedeem: {
    status: string;
    receipt: {
      txHash: string;
      status?: number;
      gasUsed?: string;
      blockNumber?: number;
      blockHash?: string;
    };
  };
}

export interface useVoucherRedeemResult {
  startVoucherRedeem: (input: VoucherRedeemRequest) => void;
  clearVoucherRedeemData: () => void;
  data: VoucherRedeemResponse | null;
  loading: boolean;
  error?: ApolloError;
}

export const VoucherRedeemContext = createContext<useVoucherRedeemResult>({
  startVoucherRedeem: () => {
    /** */
  },
  clearVoucherRedeemData: () => {
    /** */
  },
  data: null,
  loading: false,
  error: undefined,
});

interface VoucherRedeemProviderProps {
  children: React.ReactNode;
}

export const VoucherRedeemProvider = React.memo<VoucherRedeemProviderProps>(props => {
  const [voucherRedeem, { loading, error }] = useMutation<VoucherRedeemResponse, VoucherRedeemRequest>(VOUCHER_REDEEM);

  const [voucherRedeemData, setVoucherRedeemData] = useState<VoucherRedeemResponse | null>(null);

  const startVoucherRedeem = useCallback(
    ({ requestId, data, signature }: VoucherRedeemRequest) => {
      voucherRedeem({
        variables: {
          requestId,
          data,
          signature,
        },
        onCompleted: apolloData => {
          setVoucherRedeemData(apolloData);
        },
      });
    },
    [voucherRedeem],
  );

  const clearVoucherRedeemData = useCallback(() => {
    setVoucherRedeemData(null);
  }, []);

  const contextValue = useMemo(() => {
    return {
      startVoucherRedeem,
      clearVoucherRedeemData,
      data: voucherRedeemData,
      loading,
      error,
    };
  }, [voucherRedeemData, error, loading, startVoucherRedeem, clearVoucherRedeemData]);

  return <VoucherRedeemContext.Provider value={contextValue}>{props.children}</VoucherRedeemContext.Provider>;
});

export const useVoucherRedeem = (): useVoucherRedeemResult => {
  const { startVoucherRedeem, clearVoucherRedeemData, data, loading, error } = useContext(VoucherRedeemContext);

  return {
    startVoucherRedeem,
    clearVoucherRedeemData,
    data,
    loading,
    error,
  };
};
