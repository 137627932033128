import { Network } from '@qubic-js/core';
import QubicWalletConnector from '@qubic-js/react';
import { initializeConnector, Web3ReactHooks } from '@web3-react/core';
import { Empty } from '@web3-react/empty';
import { MetaMask } from '@web3-react/metamask';
import { Connector, Web3ReactStore } from '@web3-react/types';
import { WalletConnect } from '@web3-react/walletconnect-v2';

import { REQUIRED_SUPPORTED_CHAIN_IDS, OPTIONAL_SUPPORTED_CHAIN_IDS } from '@/constants/blockchain';
import { WALLET_CONNECT_METADATA, WALLET_CONNECT_PROJECT_ID } from '@/constants/config';
import { QUBIC_API_KEY, QUBIC_API_SECRET, QUBIC_INFURA_PROJECT_ID, QUBIC_WALLET_URL } from '@/constants/env';

const DEFAULT_QUBIC_WALLET_CHAIN_ID = Network.POLYGON;

const metamask = initializeConnector<MetaMask>(actions => new MetaMask({ actions }));

// due to https://github.com/MetaMask/metamask-mobile/issues/6670
// MetaMask doesn't have simultaneous multi-chain support despite migrating to WalletConnect V2.
// workaround by making all chains except ethereum mainnet to optional

const walletConnect = initializeConnector<WalletConnect>(
  actions =>
    new WalletConnect({
      actions,
      options: {
        projectId: WALLET_CONNECT_PROJECT_ID,
        chains: REQUIRED_SUPPORTED_CHAIN_IDS,
        optionalChains: OPTIONAL_SUPPORTED_CHAIN_IDS,
        showQrModal: true,
        metadata: WALLET_CONNECT_METADATA,
      },
    }),
);

const qubic = initializeConnector<QubicWalletConnector | Empty>(actions => {
  if (typeof window === 'undefined') return new Empty(actions); // dummy for next.js
  return new QubicWalletConnector({
    actions,
    options: {
      apiKey: QUBIC_API_KEY,
      apiSecret: QUBIC_API_SECRET,
      chainId: DEFAULT_QUBIC_WALLET_CHAIN_ID,
      infuraProjectId: QUBIC_INFURA_PROJECT_ID,
      walletUrl: QUBIC_WALLET_URL || undefined,
      disableIabWarning: true,
    },
  });
});

const wrappedConnectors: Record<'metamask' | 'walletConnect' | 'qubic', [Connector, Web3ReactHooks, Web3ReactStore]> = {
  metamask,
  walletConnect,
  qubic,
};

export default wrappedConnectors;
